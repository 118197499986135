import {useEffect, useState} from "react";

import axios from "axios"
import TossupContainer from "./TossupContainer";
import LoadingCircle from "./LoadingCircle";

const categoryMapping = require("../mappings/categoryMapping.json")

export default function TossupList(props) {
    const [tossups, setTossups] = useState([])
    const [haveTossups, setHaveTossups] = useState(true)
    const [debounce, setDebounce] = useState(false)

    useEffect(() => {
        if(props.params.noRender) return;
        if(debounce) return;


        setTossups([])
        //http://localhost:8080/api/tossups?type=quizdb&diffis=[1,%202,%203]&subcats=[1,%202,%203,%204,%205]&limit=5&searchtype=0&searchterm=QDBNOSEARCH&tournaments=[]&random=1
        setDebounce(true);
      const cfg = {
        headers:{
          'Access-Control-Allow-Origin': '*',
        }
      };

        let search = props.params.searchQuery.length > 0 ? props.params.searchQuery : "QDBNOSEARCH"
        if(props.params.questionType[0] === 0) {
            axios.get(`https://api.nocard.org:8080/api/tossups?type=quizdb&diffis=[${props.params.difficultyList.join(",")}]&subcats=[${props.params.subcategoryList.join(",")}]&limit=${props.params.num}&searchtype=${props.params.searchType[0]}&searchterm=${search}&tournaments=[${props.params.tournamentList.join(",")}]&random=${props.params.rand ? 1 : 0}`, cfg).then((res) => {
                if(res.data.message === "success") {
                  setTossups(res.data.data)
                  setHaveTossups(true)
                  setDebounce(false);
                } else {
                  // NO TOSSUPS FOUND
                  setHaveTossups(false)
                  setDebounce(false);
                }

            }).catch((err) => {
              setDebounce(false);
            })
        } else {
            axios.get(`https://api.nocard.org:8080/api/bonuses?type=quizdb&diffis=[${props.params.difficultyList.join(",")}]&subcats=[${props.params.subcategoryList.join(",")}]&limit=${props.params.num}&searchtype=${props.params.searchType[0]}&searchterm=${search}&tournaments=[${props.params.tournamentList.join(",")}]&random=${props.params.rand ? 1 : 0}`, cfg).then((res) => {
                if(res.data.message === "success") {
                  setTossups(res.data.data)
                  setHaveTossups(true)
                  setDebounce(false);
                } else {
                  // NO TOSSUPS FOUND
                  setHaveTossups(false)
                  setDebounce(false);
                }

            }).catch((err) => {
              setDebounce(false);
            })
        }




    }, [props.params])

    let noTossups = <><div className={"ntu-text"}>No Tossups Found</div> <div className={"ntu-text ntu-sub"}>Try searching with different parameters</div> </>
    let tossup_generated = tossups.map((tu, ind) => <TossupContainer type={props.params.questionType[0]} tossup={tu} num={ind} searchTerm={props.params.searchQuery} />);
    let tuList = tossups.length <= 0 ? <div className={"ntu-text"}>Loading...<LoadingCircle /></div> : tossup_generated

    return (

            <div className={"tuVerticalList"}>
                {haveTossups ? tuList : noTossups}
            </div>
    )

}