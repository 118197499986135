export default function LoadingCircle(props) {

    return (
        <>
            <div className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </>
    )

}