export default function SettingsButton() {
    return (
        <div className={"settings-vwrap"}>
            <div className={"settings-hwrap"}>
              <div className={"settings-wrap"}>

                  <div className={"settings-bar"} />
                  <div className={"settings-bar"} />
                  <div className={"settings-bar"} />
              </div>
            </div>
        </div>
    )
}